import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VCMaterialModule } from './design/vcmaterial.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { GtagModule } from 'angular-gtag';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, AngularFirestore, PERSISTENCE_SETTINGS } from '@angular/fire/compat/firestore';
import { SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import 'firebase/storage';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { UserAccountComponent } from './user/user-account/user-account.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { VacationModule } from './vacation/vacation.module';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { VcNotFoundModule } from './vc-not-found/vc-not-found.module';
import { AngularFireFunctions, AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { CommonModule } from '@angular/common';

export const MOMENT_DATE_FORMATS_VC: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'my23.vacationcounts.com'
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff'
    },
    button: {
      background: '#f1d600',
      text: '#000000',
      border: 'transparent'
    }
  },
  type: 'info',
  content: {
    message: 'Cookies are used by the app for login, to analyze anonymous usage data, ' +
      'and by advertising partners. By continuing to use this web app you are giving us your consent.',
    dismiss: 'I\'m OK',
    link: 'Learn more',
    href: 'https://www.vacationcounts.com/app/privacy'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    UserAccountComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    VCMaterialModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    GtagModule.forRoot({ trackingId: 'UA-15655778-6', trackPageviews: true }),
    CoreModule,
    SharedModule,
    VacationModule,
    VcNotFoundModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS_VC },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: FIRESTORE_SETTINGS, useValue: environment.firebase.projectId === 'vacationcounts-dev' &&
      environment.firebase.runEmulator ? { // custom config
      host: 'localhost:8080',
      ssl: false } : undefined
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private afd: AngularFirestore,
              private aff: AngularFireFunctions,
              /* private afs: AngularFireStorage, */
              private afa: AngularFireAuth) {
    if (environment.firebase.projectId === 'vacationcounts-dev' && environment.firebase.runEmulator) {
      // cached data will be returned even when running locally so need to clear for dev emulators
      this.afd.firestore.clearPersistence();
      this.aff.useFunctionsEmulator('http://localhost:5001');
      /* this.afs.useEmulator('http://localhost:9199'); not implemented - upgrade to firebase 9 and angularfire 7 */
      firebase.app().storage().useEmulator('localhost', 9199);
      this.afa.useEmulator('http://localhost:9099');
    } else {
      this.afd.firestore.enablePersistence();
    }
  }
}
